<template>
  <div class="relative">
    <BackFr class="top-5 -right-4 absolute" v-if="isLtr" />
    <BackFa v-if="isRtl" />
    <div :class="{ 'm-5': isRtl, 'mx-12': isLtr, 'mt-5': isLtr }">
      <div class="border border-gray-400">
        <div class="m-3">
          <div class="flex justify-between items-center px-4 py-1 bg-primary">
            <div class="flex">
              <div v-if="isRtl" class="flex flex-col -mt-1">
                <i class="text-lime-300 fas fa-caret-up"></i>
                <i class="-mt-2 text-lime-300 fas fa-caret-down"></i>
              </div>
              <h3 class="text-white mr-5">{{ $t('title_repors') }}</h3>
            </div>
            <div>
              <div v-if="isLtr" class="flex flex-col -mt-1">
                <i class="text-lime-300 fas fa-caret-up"></i>
                <i class="-mt-2 text-lime-300 fas fa-caret-down"></i>
              </div>
              <i
                v-if="isRtl"
                @click="$emit('toggle')"
                class="cursor-pointer text-white text-xl fas fa-caret-up"
              ></i>
            </div>
          </div>
          <BaseTable
            class="text-right"
            header-class="bg-gray-50 text-gray-500  text-base"
            :headers="headers1"
            :items="items1"
            dividable
            theme-type="odd"
          >
            <template #item:document="{ item }">
              <img width="20" class="mx-auto" :src="item.document" alt="" />
            </template>
            <template #item:allergy="{ item }">
              <div>{{ item.allergy }}</div>
            </template>
            <template #item:specialty="{ item }">
              <div
                @click="goto(item.to)"
                class="cursor-pointer hover:text-cyan-400 duration-500"
              >
                {{ item.specialty }}
              </div>
            </template>
          </BaseTable>
        </div>
      </div>
      <div class="m-5">
        <BaseTable
          class="text-right mx-auto mt-6"
          header-class="bg-primary text-white  text-base"
          :headers="headers2"
          :items="items2"
          dividable
          theme-type="odd"
        >
          <template #item:upload_file="{ item }">
            <div class="text-center">
              <input
                type="checkbox"
                class="form-checkbox h-4 w-4"
                :checked="item.upload_file"
              />
            </div>
          </template>
          <template #item:subject>
            <div class="text-center w-32"></div>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'
import BackFr from '../components/back/BackFr.vue'

export default {
  name: 'Reports',
  components: { BaseTable, BackFr, BackFa },
  computed: {
    headers1() {
      return [
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'specialty',
          text: this.$t('specialty_reports'),
        },
        {
          key: 'docter',
          text: this.$t('doctor_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports'),
        },
        {
          key: 'document',
          text: this.$t('document_reports'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
      ]
    },
    items1() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('name_nurse_reports'),
          docter: this.$t('name_doctor_reports1'),
          subject: this.$t('subject_text2_repors'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports2'),
          to: { name: 'ReportsNurse' },
        },
        {
          date: this.convertDate('2021-2-2'),
          specialty: this.$t('name_nurse_reports2'),
          docter: this.$t('name_doctor_reports2'),
          subject: this.$t('subject_text_repors2'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports2'),
        },
        {
          date: this.convertDate('2021-2-15'),
          specialty: this.$t('name_nurse_reports3'),
          docter: this.$t('name_doctor_reports3'),
          subject: this.$t('subject_text_repors3'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
          to: { name: 'ReportsDentis' },
        },
        {
          date: this.convertDate('2021-2-28'),
          specialty: this.$t('name_nurse_reports4'),
          docter: this.$t('name_doctor_reports4'),
          subject: this.$t('subject_text_repors4'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
        },
        {
          date: this.convertDate('2021-3-10'),
          specialty: this.$t('name_nurse_reports5'),
          docter: this.$t('name_doctor_reports5'),
          subject: this.$t('subject_text_repors5'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
          to: { name: 'Laboratory' },
        },
        {
          date: this.convertDate('2021-4-3'),
          specialty: this.$t('name_nurse_reports6'),
          docter: this.$t('name_doctor_reports6'),
          subject: this.$t('subject_text_repors6'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
          to: { name: 'Electrocardiogram' },
        },
        {
          date: this.convertDate('2021-4-7'),
          specialty: this.$t('name_nurse_reports2'),
          docter: this.$t('name_doctor_reports2'),
          subject: this.$t('subject_text_repors7'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
        },
        //           "../assets/images/theme/edit-icon.png"
        // "../assets/images/theme/text-icon.png"
        // "../assets/images/theme/comment-icon.png"
        // "../assets/images/theme/text-left-icon.png"
        // "../assets/images/theme/edit-pen-icon.png"
        // "../assets/images/theme/circle-icon.png"
        // "../assets/images/theme/text-icon.png"
      ]
    },
    headers2() {
      return [
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'specialty',
          text: this.$t('specialty_reports'),
        },
        {
          key: 'docter',
          text: this.$t('doctor_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports'),
        },
        {
          key: 'upload_file',
          text: this.$t('upload_file'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
      ]
    },
    items2() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('name_nurse2_reports'),
          docter: this.$t('name_doctor2_reports'),
          upload_file: false,
          allergy: this.$t('message_reports2'),
        },
      ]
    },
  },
  methods: {
    goto(to) {
      !!to && this.$router.push(to)
    },
  },
}
</script>

<style></style>
